import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'utils/api';
import { buildQuery } from 'utils/fx';
import { format } from 'date-fns';

interface CalledBy {
  userId: string;
  userName: string;
  email: string;
}

export interface CallLog {
  id: string;
  leadId: string;
  name: string;
  calledBy: CalledBy;
  summary: string;
  statusByCall: string;
  statusAfterCall: string;
  dateTime: string;
  isApproved: boolean;
  comments: any[];
  createdAt: string;
  updatedAt: string;
}

interface Filters {
  page: number;
  limit: number;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  statusByCall?: string;
  statusAfterCall?: string;
  sortBy?: string;
  leadId?: string;
  calledBy?: string;
}

export const useCallLogs = (initialFilters?: Partial<Filters>) => {
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState<Filters>({
    page: 1,
    limit: 10,
    ...initialFilters
  });

  // Fetch call logs
  const { data, isLoading, error } = useQuery(
    ['callLogs', filters],
    async () => {
      const params = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          if (value instanceof Date) {
            params.append(key, format(new Date(value), "yyyy-MM-dd"));
          } else {
            params.append(key, String(value));
          }
        }
      });
      const endpoint = 'migration/v1/call-logs';
      const response = await api.get(`${endpoint}?${params.toString()}`);
      return {
        results: response.data.data.results,
        totalResults: response.data.data.totalResults,
        page: response.data.data.page,
        limit: response.data.data.limit,
        totalPages: response.data.data.totalPages
      };
    }
  );

  // Call log analytics
  const { data: analytics } = useQuery(
    ['callLogsAnalytics', filters],
    async () => {
      const query = buildQuery({dateFrom: filters.dateFrom, dateTo: filters.dateTo});
      
      const endpoint = 'migration/v1/call-logs/metrics';
      const response = await api.get(`${endpoint}?${query}`);
      return response.data.data;
    }
  );
  // Add new call log
  const addCallLog = useMutation(
    async (callLog: {
      leadId: string;
      summary: string;
      statusByCall: string;
      statusAfterCall: string;
      dateTime: string;
    }) => {
      const response = await api.post(`migration/v1/call-logs/${callLog.leadId}`, {
        summary: callLog.summary,
        statusByCall: callLog.statusByCall,
        statusAfterCall: callLog.statusAfterCall,
        dateTime: callLog.dateTime,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['callLogs']);
      },
    }
  );

  // Approve call log
  const approveCallLog = useMutation(
    async (callLogId: string) => {
      const response = await api.post(`migration/v1/call-logs/${callLogId}/approve`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['callLogs']);
      },
    }
  );

  // Add comment to call log
  const addComment = useMutation(
    async ({ callLogId, comment }: { callLogId: string; comment: string }) => {
      const response = await api.post(`migration/v1/call-logs/${callLogId}/comments`, {
        comment,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['callLogs']);
      },
    }
  );

  return {
    callLogs: data?.results || [],
    analytics,
    totalResults: data?.totalResults || 0,
    page: data?.page || 1,
    limit: data?.limit || 50,
    totalPages: data?.totalPages || 1,
    isLoading,
    error,
    filters,
    setFilters,
    addCallLog,
    approveCallLog,
    addComment,
  };
}; 